import { isParallelWaitError, parallelExecute } from '@creator-portal/common/util/async';
import { notNullish } from '@creator-portal/common/util/filters';
import { EPIC_CORRELATION_ID_HEADER } from '@creator-portal/common/http';
import { LinkCode } from '@creator-portal/common/links';
import { PERSONAL_TEAM_ID } from '@creator-portal/common/publishing/constants';
import { ProjectSearchResult } from '@creator-portal/common/types';

import { log } from '@/util/logging';
import * as Xhr from '@/util/xhr';
import { XhrResponse } from '@/util/xhr';

import { PublishBuildDto } from '@/types/publishing';

import type { PublishedLink } from '@creator-portal/common/content-service/types';
import type {
  LinkCodeData,
  LinkCodeInfo,
  LinkVersionInfo,
  PagedResults,
  PostLinkMediaUploadResponse,
  ProjectRelease,
  UpdateDiscoveryIntentRequest,
} from '@creator-portal/common/types';

export const MAX_RELEASES_RESULTS = 10;
export const PROJECTS_INITIAL_REQUEST_LIMIT = 12;
export const PROJECTS_REQUEST_LIMIT = 100;

export enum DiscoveryErrors {
  VIDEO_CODEC = 'VIDEO_CODEC',
  AUDIO_CODEC = 'AUDIO_CODEC',
  VIDEO_ASPECT_RATIO = 'VIDEO_ASPECT_RATIO',
  VIDEO_RESOLUTION = 'VIDEO_RESOLUTION',
  IMG_ASPECT_RATIO = 'IMG_ASPECT_RATIO',
  IMG_RESOLUTION = 'IMG_RESOLUTION',
}

// type DiscoveryResponse = {
//   success: boolean;
//   errorCodes?: DiscoveryErrors[];
//   response?: LinkCodeData;
// };

export type DiscoverySubmissionErrorCodes = {
  errorCodes: string[];
};

export namespace PublishingUrl {
  export const projectDetails = (projectId: string) => `/api/vk/v1/projects/${encodeURIComponent(projectId)}` as const;
  export const projectPlaytestInfo = (projectId: string) => `/api/vk/v1/projects/${encodeURIComponent(projectId)}/playtestcodes` as const;
  export const projectUserInfo = (projectId: string) => `/api/vk/v1/projects/${encodeURIComponent(projectId)}/user` as const;
  export const projectReleases = (projectId: string) => `/api/vk/v1/projects/${encodeURIComponent(projectId)}/releases` as const;
  export const projectBuildCodes = (projectId: string) => `/api/vk/v1/projects/${encodeURIComponent(projectId)}/buildcodes` as const;
  export const ownerCreatorCode = (projectId: string) => `/api/vk/v1/projects/${encodeURIComponent(projectId)}/owner/creator-code` as const;
  export const getIslandDevices = (code: string, version: string, device: string) =>
    `/api/links/v1/${encodeURIComponent(code)}/${encodeURIComponent(version)}/devices?devices=${device}` as const;

  export const linkVersionInfo = (linkCode: string) => `/api/links/v1/${encodeURIComponent(linkCode)}/versions` as const;
  export const linkCodeData = (linkCode: string, version: number) =>
    `/api/links/v1/${encodeURIComponent(linkCode)}/${encodeURIComponent(version)}` as const;
  export const linkBuildStatus = (linkCode: string, version: number) =>
    `/api/links/v1/${encodeURIComponent(linkCode)}/${encodeURIComponent(version)}/build-status` as const;
  export const projectsList = (teamId: string, mode?: string, oldestDate?: string, limit: number = PROJECTS_REQUEST_LIMIT): string => {
    const projectRequestURL =
      teamId !== PERSONAL_TEAM_ID ? `/api/vk/v1/teams/${encodeURIComponent(teamId)}/projects` : '/api/vk/v1/projects';
    let parameters = oldestDate
      ? `?olderThan=${oldestDate.toString()}&limit=${encodeURIComponent(limit)}`
      : `?limit=${encodeURIComponent(limit)}`;

    if (mode) {
      parameters = `${parameters}&mode=${encodeURIComponent(mode)}`;
    }

    return `${projectRequestURL}${parameters}`;
  };
}

// TODO: Reimplement response type
export const publishBuild = async (buildCode: LinkCodeInfo, correlationId: string, dto: PublishBuildDto): Promise<any> => {
  // Promise<InitDiscoverySubmissionResponse> =>
  const xhr = Xhr.getInstance();

  const response = await xhr.fetchJson(
    `/api/links/v1/${encodeURIComponent(buildCode.linkCode)}/${encodeURIComponent(buildCode.version)}/publish`,
    {
      method: 'POST',
      headers: {
        [EPIC_CORRELATION_ID_HEADER]: correlationId,
      },
      body: JSON.stringify(dto),
    },
  );

  if (!response.success) {
    const errors = response.data?.errors;

    if (errors) {
      const errorCodes: DiscoveryErrors[] = [];
      for (const error of errors) {
        switch (error.property) {
          case 'ASPECT_RATIO':
            {
              if (error.type === 'trailer') errorCodes.push(DiscoveryErrors.VIDEO_ASPECT_RATIO);
              else if (error.type === 'image') errorCodes.push(DiscoveryErrors.IMG_ASPECT_RATIO);
            }
            break;
          case 'RESOLUTION':
            {
              if (error.type === 'trailer') errorCodes.push(DiscoveryErrors.VIDEO_RESOLUTION);
              else if (error.type === 'image') errorCodes.push(DiscoveryErrors.IMG_RESOLUTION);
            }
            break;
          case 'VIDEO_CODEC':
            {
              errorCodes.push(DiscoveryErrors.VIDEO_CODEC);
            }
            break;
          case 'AUDIO_CODEC':
            {
              errorCodes.push(DiscoveryErrors.AUDIO_CODEC);
            }
            break;
        }

        if (errorCodes.length > 0) {
          return {
            success: false,
            errorCodes,
          };
        }
      }
      // Unhandled error
      Xhr.throwOnFailure(response);
    }
  }

  Xhr.throwOnFailure(response);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return response.data as any; // InitDiscoverySubmissionResponse;
};

export type InitMediaUploadModel = {
  image?: File;
  trailer?: File;
  squareImage?: File;
  lobbyBackground?: File;
};

const prepareInitFileDto = (file?: File) => {
  if (!file) return undefined;

  return {
    contentType: file.type,
    name: file.name,
    contentLength: file.size,
  };
};

const prepareInitMediaUploadDto = (model: InitMediaUploadModel) => ({
  image: prepareInitFileDto(model.image),
  trailer: prepareInitFileDto(model.trailer),
  squareImage: prepareInitFileDto(model.squareImage),
  lobbyBackground: prepareInitFileDto(model.lobbyBackground),
});

export const initMediaUpload = async (linkCode: LinkCodeInfo, model: InitMediaUploadModel): Promise<PostLinkMediaUploadResponse> => {
  // Promise<InitMediaUploadResponse> =>
  const xhr = Xhr.getInstance();

  const response = await xhr.fetchJson<PostLinkMediaUploadResponse>(
    `/api/links/v1/${encodeURIComponent(linkCode.linkCode)}/${encodeURIComponent(linkCode.version)}/upload-media`,
    {
      method: 'POST',
      body: JSON.stringify(prepareInitMediaUploadDto(model)),
    },
  );

  Xhr.throwOnFailure(response);

  console.log(`new media submissionId: ${response.data.mediaSubmissionId || '-none'}`);

  return response.data;
};

export async function validatePublish(projectId: string, baseCode: LinkCode): Promise<void> {
  const xhr = Xhr.getInstance();

  const rsp = await xhr.fetchJson(`/api/vk/v1/projects/${encodeURIComponent(projectId)}/publish/validate`, {
    method: 'POST',
    body: JSON.stringify(baseCode),
  });

  Xhr.throwOnFailure(rsp);
}

export const setActiveVersion = async (linkCode: LinkCodeInfo): Promise<void> => {
  const xhr = Xhr.getInstance();

  const response = await xhr.fetchJson(
    `/api/links/v1/${encodeURIComponent(linkCode.linkCode)}/${encodeURIComponent(linkCode.version)}/activate`,
    {
      method: 'POST',
    },
  );

  Xhr.throwOnFailure(response);

  return;
};

export const setProjectToPublic = async (projectId: string): Promise<void> => {
  const xhr = Xhr.getInstance();

  const response = await xhr.fetchJson(`/api/vk/v1/projects/${encodeURIComponent(projectId)}/public`, {
    method: 'POST',
  });

  Xhr.throwOnFailure(response);

  return;
};

export const setProjectDiscoveryIntent = async (projectId: string, dto: UpdateDiscoveryIntentRequest): Promise<void> => {
  const xhr = Xhr.getInstance();

  const response = await xhr.fetchJson(`/api/vk/v1/projects/${encodeURIComponent(projectId)}/discovery-intent`, {
    method: 'PUT',
    body: JSON.stringify(dto),
  });

  Xhr.throwOnFailure(response);
};

export const setProjectToPrivate = async (projectId: string): Promise<void> => {
  const xhr = Xhr.getInstance();

  const response = await xhr.fetchJson(`/api/vk/v1/projects/${encodeURIComponent(projectId)}/public`, {
    method: 'DELETE',
  });

  Xhr.throwOnFailure(response);

  return;
};

export const revertProjectToFNC = async (projectId: string): Promise<void> => {
  const xhr = Xhr.getInstance();

  const response = await xhr.fetchJson(`/api/vk/v1/projects/${encodeURIComponent(projectId)}/set-fnc`, {
    method: 'PUT',
  });

  Xhr.throwOnFailure(response);

  return;
};

export const getProjectBuildCodes = async (
  projectId: string,
  xhr: Xhr.XhrService = Xhr.getInstance(),
): Promise<PagedResults<PublishedLink>> => {
  const response = await xhr.fetchJson<PagedResults<PublishedLink>>(PublishingUrl.projectBuildCodes(projectId), {
    method: 'GET',
  });

  Xhr.throwOnFailure(response);

  return response.data;
};

export const getLinkCodeDataFromBuildCodes = async (
  buildCodes: PagedResults<PublishedLink>,
  xhr: Xhr.XhrService = Xhr.getInstance(),
): Promise<LinkCodeData[]> => {
  const tasks: Promise<LinkCodeData | undefined>[] = [];
  buildCodes?.results?.forEach((b) => {
    tasks.push(getLinkCodeData({ linkCode: b.linkCode, version: b.linkVersion }, xhr));
  });

  try {
    return (await parallelExecute(tasks)).filter(notNullish);
  } catch (e) {
    if (isParallelWaitError<LinkCodeData>(e)) {
      log.error(e, 'getting link code data from build codes', true, { errors: e.errors });

      return e.results.filter(notNullish); // filter out failed results
    }

    // re-throw uncaught errors
    throw e;
  }
};

export const getLinkCodeData = async (
  linkCode: LinkCodeInfo,
  xhr: Xhr.XhrService = Xhr.getInstance(),
): Promise<LinkCodeData | undefined> => {
  const url = PublishingUrl.linkCodeData(linkCode.linkCode, linkCode.version);

  const response = await xhr.fetchJson<LinkCodeData>(url, {
    method: 'GET',
  });

  if (!response.success && response.status === 403 && response.data.errorCode.endsWith('link_disabled')) return undefined;

  Xhr.throwOnFailure(response);

  return response.data;
};

export const getLinkCodeVersionInfo = async (
  linkCode: string | undefined,
  xhr: Xhr.XhrService = Xhr.getInstance(),
): Promise<LinkVersionInfo | null> => {
  if (!linkCode) return null;

  const response = await xhr.fetchJson<LinkVersionInfo>(PublishingUrl.linkVersionInfo(linkCode), { method: 'GET' });
  Xhr.throwOnFailure(response);

  return response.data;
};

export const getProjectReleases = async (
  projectId: string,
  xhr: Xhr.XhrService = Xhr.getInstance(),
): Promise<PagedResults<ProjectRelease>> => {
  const response = await xhr.fetchJson<PagedResults<ProjectRelease>>(PublishingUrl.projectReleases(projectId), { method: 'GET' });
  Xhr.throwOnFailure(response);

  return response.data;
};

export const starProject = async (projectId: string): Promise<void> => {
  const xhr = Xhr.getInstance();

  const response = await xhr.fetchJson(`/api/vk/v1/projects/${encodeURIComponent(projectId)}/star`, {
    method: 'PUT',
  });

  Xhr.throwOnFailure(response);

  return;
};

export const unstarProject = async (projectId: string): Promise<void> => {
  const xhr = Xhr.getInstance();

  const response = await xhr.fetchJson(`/api/vk/v1/projects/${encodeURIComponent(projectId)}/star`, {
    method: 'DELETE',
  });

  Xhr.throwOnFailure(response);

  return;
};

export const archiveProject = async (projectId: string): Promise<void> => {
  const xhr = Xhr.getInstance();

  const response = await xhr.fetchJson(`/api/vk/v1/projects/${encodeURIComponent(projectId)}/archive`, {
    method: 'POST',
  });

  Xhr.throwOnFailure(response);

  return;
};

export const unarchiveProject = async (projectId: string): Promise<void> => {
  const xhr = Xhr.getInstance();

  const response = await xhr.fetchJson(`/api/vk/v1/projects/${encodeURIComponent(projectId)}/archive`, {
    method: 'DELETE',
  });

  Xhr.throwOnFailure(response);

  return;
};

export const getProjects = async (
  teamId: string,
  xhr: Xhr.XhrService = Xhr.getInstance(),
  mode?: string,
  oldestDate?: string,
  limit: number = PROJECTS_REQUEST_LIMIT,
): Promise<PagedResults<ProjectSearchResult> | Xhr.ErrorPayload> => {
  const projects: PagedResults<ProjectSearchResult> = { results: [], limit };
  const url = PublishingUrl.projectsList(teamId, mode, oldestDate, limit);
  const response: XhrResponse<PagedResults<ProjectSearchResult>> | undefined = await xhr.fetchJson<PagedResults<ProjectSearchResult>>(url, {
    method: 'GET',
  });
  Xhr.throwOnFailure(response);

  projects.results.push(...response.data.results);
  projects.results.sort((a, b) => (a.date > b.date ? -1 : 1));

  return { ...projects, next: response.data?.next || null };
};
