import { FC, useMemo } from 'react';
import { DialogComposer } from '@epic-ui/components';
import { AddIcon } from '@epic-ui/icons';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { clsx } from 'clsx';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useParams, usePathname, useRouter, useSearchParams } from 'next/navigation';

import { AuthAction, canAccess } from '@creator-portal/common/auth';
import { PERMISSION } from '@creator-portal/common/permissions/constants';

import { MAX_TEAMS_PER_ACCOUNT } from '@/config/common/constants';

import { sortByRolesAndNames } from '@/services/teams/utils';

import TeamCard from '@/components/common/navigation/team-card';
import { BootstrapTooltip } from '@/components/common/tooltip.component';
import Button from '@/components/ui/Button';

import { useAuthSession } from '@/hooks/useAuthSession';
import { getPersonalTeam, Roles } from '@/util/teams';

import useStyles from './switch-team-dialog.styles';

import type { TeamSearchResult } from '@creator-portal/common/types';

interface SwitchTeamDialogProps {
  isOpen: boolean;
  onClose: () => void;
  teams?: TeamSearchResult[];
}
export interface TeamDefaultInfo {
  teamName: string;
  teamDescription: string;
}

// It needs to set in query parameter dialogId when Dialog active
export const DIALOG_ID = 'SwitchTeamDialog';

const SwitchTeamDialog: FC<SwitchTeamDialogProps> = ({ isOpen, onClose, teams = [] }) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const params = useParams();
  const pathname = usePathname();
  const classes = useStyles();
  const user = useAuthSession();
  const { t } = useTranslation();
  const theme = useTheme();
  const showTooltipBottom = useMediaQuery(theme.breakpoints.down('sm')) || theme.direction === 'rtl';

  const personalTeamName = t('user-card.team.my-projects');
  const personalTeam = getPersonalTeam(personalTeamName, user?.displayName);
  const selectedTeamMemberId = searchParams?.get('team') ?? params?.team;
  const encodedTeamId = encodeURIComponent((selectedTeamMemberId as string) || 'personal');
  const hasUncappedMaxTeamCountAccess = canAccess(user, PERMISSION.UNCAPPED_MAX_TEAM_PER_USER, AuthAction.READ);
  const isTeamsPerAccountLimitExceeded = useMemo(
    () =>
      !hasUncappedMaxTeamCountAccess &&
      teams.filter(({ membership }) => membership?.cpRole === Roles.OWNER).length >= MAX_TEAMS_PER_ACCOUNT,
    [teams],
  );

  const handleChangeTeam = async (teamId: string) => {
    const isProjectPageOpened = !!params?.projectId;
    const isJoinTeamPageOpened = pathname?.includes('/Join_Team');
    const isPlaytestersPageOpened = pathname?.includes('/playtesters');

    const queryParams = new URLSearchParams(searchParams?.toString());

    let redirectPath = '';
    if (isProjectPageOpened || isJoinTeamPageOpened) {
      redirectPath = `/${teamId}/projects`;
    } else if (isPlaytestersPageOpened) {
      redirectPath = `/${teamId}/teams`;
    } else {
      // If we're not directly setting the teamId in the URL, we need to add it as a query parameter
      queryParams.set('team', teamId);
    }

    // Remove dialogId query parameter to close the switchTeam dialog
    queryParams.delete('dialogId');

    const redirectUrl = `${redirectPath}?${queryParams.toString()}`;

    return router.push(redirectUrl);
  };

  const handleTeamSelected = (member: TeamSearchResult) => {
    selectedTeamMemberId !== member?.teamId && void handleChangeTeam(member?.teamId);
  };

  const dialogTitle = (
    <Typography className={classes.title} variant="h1">
      {t('modal.switch-team.title')}
    </Typography>
  );

  return (
    <DialogComposer
      data-testid={DIALOG_ID}
      dialogTitle={dialogTitle}
      displayCloseButton
      onClickClose={onClose}
      onClose={onClose}
      open={isOpen}
      className={classes.dialog}
    >
      <div className={clsx(classes.top, classes.withSidePadding)}>
        <div className={classes.wrapper}>
          <Typography variant="h2" className={classes.subTitle}>
            {t('modal.switch-team.sub-title')}
          </Typography>
          <Link
            className={classes.link}
            href={{ pathname: `/${encodedTeamId}/create_team` }}
            onClick={(e) => (isTeamsPerAccountLimitExceeded ? e.preventDefault() : null)}
          >
            <BootstrapTooltip
              title={isTeamsPerAccountLimitExceeded ? t('modal.switch-team.add.limit-exceeded.tooltip') : ''}
              placement={showTooltipBottom ? 'bottom' : 'left'}
            >
              <div className={classes.buttonWrapper}>
                <Button
                  variant="outlined"
                  icon={<AddIcon />}
                  disabled={isTeamsPerAccountLimitExceeded}
                  className={clsx(classes.mobileButton, 'capitalized-text')}
                >
                  {t('modal.switch-team.add')}
                </Button>
              </div>
            </BootstrapTooltip>
          </Link>
        </div>
      </div>
      <div className={classes.scrollWrapper}>
        <div className={clsx(classes.bottom, classes.withSidePadding)}>
          {[personalTeam, ...sortByRolesAndNames(teams)].map((member, idx) => {
            const isCurrentTeam = selectedTeamMemberId === member?.teamId;
            const teamMemberRole = member?.membership?.cpRole;
            if (teamMemberRole === Roles.PLAYTESTER) return null;

            return (
              <div key={idx} className={clsx(classes.teamCard, !isCurrentTeam && classes.standardTitleColor)} data-testid="userCard">
                <div className={clsx(isCurrentTeam && classes.currentCardWrapper)} id="cardWrapper">
                  {isCurrentTeam && <span className={classes.currentLabel}>{`${t('modal.switch-team.selected-card.label')}:`}</span>}
                </div>
                <TeamCard
                  onClick={() => handleTeamSelected(member)}
                  activeTeamName={member?.name}
                  teamMemberRole={teamMemberRole}
                  memberCount={member?.memberCount}
                />
              </div>
            );
          })}
        </div>
      </div>
    </DialogComposer>
  );
};

export default SwitchTeamDialog;
