export const PERMISSION = {
  // Admin permissions.  These are not feature flags and cannot be set in Launch Darkly.
  ADMIN: 'creator-portal:admin:*',
  ADMIN_IMPERSONATE: 'creator-portal:admin:impersonate',
  ADMIN_IARC: 'creator-portal:admin:iarc',
  ADMIN_DEBUG: 'creator-portal:admin:debug',
  ADMIN_PROFILE: 'creator-portal:admin:profile',
  ADMIN_ANALYTICS: 'creator-portal:admin:analytics',
  // Strictly FTE only permission
  ADMIN_FTE: 'creator-portal:admin:fte',

  // Content Service
  UNCAPPED_MAX_TEAM_PER_USER: 'content-service:limit-grants:uncapped_max_team_per_user',

  // Feature flags
  IARC_STAGE_2: 'creator-portal:iarc-stage-2',
  SHOW_REPORT_CONTENT: 'creator-portal:show-report-content',
  ISLAND_CREATOR_PROGRAM_ENABLED: 'creator-portal:island-creator-program-enabled',
  SAC_PROGRAM_ENABLED: 'creator-portal:sac-program-enabled',
  BYPASS_PUBLIC_API_COMPATIBILITY: 'creator-portal:bypass-public-api-compatibility',
  BYPASS_MEMORY_AND_DOWNLOAD_CHECK: 'creator-portal:bypass-memory-and-download-check',
  BYPASS_IARC_ENV_REQUIREMENT: 'creator-portal:bypass-iarc-env-requirement',
  MEDIA_PAGE: 'creator-portal:media-page',
  TWO_BANNERS_FOR_IARC: 'creator-portal:two-banners-for-iarc',
  PERMIT_USK_16: 'creator-portal:permit-usk-16',
  VERSE_PATH_VIA_UEFN: 'creator-portal:verse-path-via-uefn',

  // temporary tags - should be removed after 28.10 (see FNCE-3898)
  ICP_FEATURE: 'creator-portal:icp-feature',
  ICP_FEATURE_STAGE_2: 'creator-portal:icp-feature-stage-2',
  SHOW_TAG_JAM_SYSTEM: 'creator-portal:temp-tag-jam-system',
  SHOW_TAG_PATCHWORK: 'creator-portal:temp-tag-patchwork',
  SHOW_SUBSCRIBE_TO_EMAIL_ALERT: 'creator-portal:show-subscribe-to-email-alert',
  SHOW_GRAC_TOOLTIP: 'creator-portal:show-grac-tooltip',

  // temporary tag - should be removed when analytics is no longer a new feature
  SHOW_ANALYTICS_LAUNCH_ALERT: 'creator-portal:show-analytics-launch-alert',

  // temporary tags until Content Originality report launch
  IPC_ORIGINALITY: 'creator-portal:ipc-originality',
  IPC_ORIGINALITY_ADMIN: 'creator-portal:ipc-originality-admin',

  SHOW_EPICS_PICKS: 'creator-portal:epics-picks',

  // LEGO Partner Creator Program feature
  LEGO_FEATURE: 'creator-portal:lego-feature',
  DEL_MAR_ROCKET_RACING_FEATURE: 'creator-portal:del-mar-rocket-racing',

  DEACTIVATE_ISLAND_CODE_FEATURE: 'creator-portal:deactivate-island-code',
  FALL_GUYS_FEATURE: 'creator-portal:fall-guys',
  BED_WARS_TAG_FEATURE: 'creator-portal:bed-wars-tag',
  FALL_GUYS_ISLANDS_PUBLISHING_FEATURE: 'creator-portal:fall-guys-islands-publishing',

  TMNT_FEATURE: 'creator-portal:tmnt',
  TMNT_ISLANDS_PUBLISHING_FEATURE: 'creator-portal:tmnt-islands-publishing',

  PROFILE_V2: 'creator-portal:profile-v-2',
  PROFILE_FLW: 'creator-portal:profile-flw',
  PROFILE_FLW_DOWNLOAD: 'creator-portal:profile-flw-download',
  PROFILE_SA: 'creator-portal:profile-sa',
  //Project sub navigation related flags
  PROJECT_MEDIA: 'creator-portal:project-media',
  PROJECT_DASHBOARD: 'creator-portal:project-dashboard',
  PROJECT_MESSAGE_OF_THE_DAY: 'creator-portal:project-motd',
  PROJECT_MONETIZATION: 'creator-portal:project-monetization',
  PROJECT_ANALYTICS: 'creator-portal:project-analytics',
  PROJECT_SHARE_LINK_AND_SETTINGS_BTN: 'creator-portal:share-link',
  // Session Length
  PROJECT_ANALYTICS_SL: 'creator-portal:analytics-sl',
  // Clicks
  PROJECT_ANALYTICS_CL: 'creator-portal:analytics-cl',
  // Active Players/Playtime
  PROJECT_ANALYTICS_APL: 'creator-portal:analytics-apl',
  // Favorites
  ANALYTICS_FV: 'creator-portal:analytics-fv',
  ANALYTICS_TABS_UI: 'creator-portal:analytics-tabs-ui',

  // Plays
  PROJECT_ANALYTICS_PL: 'creator-portal:analytics-pl',

  // Satisfaction tab
  PROJECT_ANALYTICS_ST: 'creator-portal:analytics-st',

  ENABLE_CANCEL_RELEASE: 'creator-portal:enable-cancel-release',

  VERSE_RUNTIME_ERRORS: 'creator-portal:verse-path-errors',

  PAYOUTS_IN_TURKEY: 'creator-portal:payouts-in-turkey',
  PAYOUTS_IN_TUKEY_PROFILE: 'creator-portal:payouts-in-turkey-profile',

  // Audience tab
  ANALYTICS_AT: 'creator-portal:analytics-at',

  // W8 Tax Forms Warning Banner
  W8_BANNER: 'creator-portal:w8-banner',
};
