export const TITLE_FORBIDDEN_CHARACTERS = ['🆕']; // to check for multiple charcters: ['🆕', '⭐'];
export const TITLE_FORBIDDEN_CHARACTERS_REGEXP = new RegExp(`^((?!(${TITLE_FORBIDDEN_CHARACTERS.join('|')})).)*$`, 'i');
// Assigned: for this 'supportCode == 'thelegogroup'' the rule about the inadmissibility of 'lego' text in lego projects is ignored
export const ACCEPTABLE_LEGO_SUPPORT_CODE = 'thelegogroup';
export const LEGO_FORBIDDEN_STRING = ['lego', 'leg0'];
export const LEGO_FORBIDDEN_STRING_REGEXP = new RegExp(`\\w*(?:${LEGO_FORBIDDEN_STRING.join('|')})\\w*`, 'i');
export const MIN_GAME_TAGS = 1;
export const MAX_GAME_TAGS = 4;
// BED_WARS_TAG since the tag is protected by a feature flag, it is convenient to make it a constant (when we get rid of the feature flag, we can use a tag without a constant, a regular string like tags in SUPPORTED_GAME_TAGS)
export const BED_WARS_TAG = 'bed wars';
export const SUPPORTED_GAME_TAGS: string[] = [
  '1v1',
  '2v2',
  '3v3',
  '4v4',
  '5v5',
  '6v6',
  '7v7',
  '8v8',
  'action',
  'ad',
  'adventure',
  'aim course',
  'arcade',
  'arena',
  'artistic',
  'attack',
  'base',
  'battle',
  'battle royale',
  'board game',
  'boss battle',
  'boxfight',
  'building',
  'capture the flag',
  'casual',
  'classes',
  'co-op',
  'collection',
  'competitive',
  'crafting',
  'creatures',
  'deathrun',
  'defend',
  'difficulty: easy',
  'difficulty: hard',
  'difficulty: impossible',
  'difficulty: medium',
  'difficulty: ultra hard',
  'difficulty: very hard',
  'duo',
  'economy',
  'edit course',
  'educational',
  'escape',
  'event',
  'exploration',
  'explosives',
  'fashion',
  'free for all',
  'friendly',
  'first person',
  'gun fight',
  'gun game',
  'hardcore',
  'heroes',
  'horror',
  'hub',
  'infection',
  'jam system',
  'just for fun',
  'king of the hill',
  'moba',
  'minigame',
  'moving',
  'music',
  'mystery',
  'nonlinear',
  'objective',
  'one life',
  'one in the chamber',
  'open world',
  'parkour',
  'party game',
  'party world',
  'patchwork',
  'peaceful',
  'pinball',
  'point capture',
  'practice',
  'prop',
  'prop hunt',
  'pve',
  'pvp',
  'puzzle',
  'quiz',
  'race',
  'respawn',
  'role playing',
  'rounds',
  'runner',
  'search & destroy',
  'seasonal',
  'secrets',
  'series',
  'shooter',
  'shop',
  'show',
  'simulator',
  'single player',
  'skills',
  'sniper',
  'solo',
  'sports',
  'squad',
  'stealth',
  'story',
  'strategy',
  'survival',
  'team deathmatch',
  'teams',
  'timed',
  'tower defense',
  'training',
  'trios',
  'tutorial',
  'tycoon',
  'unbalanced teams',
  'zonewars',
  'sequel',
  'fortnitemares',
  'winterfest',
  'visual novel',
  'base building',
  'memes',
  'card game',
  'farming simulator',
  'mining',
  'episodic',
  'trivia',
  'clicker',
  'survival horror',
  'atmospheric',
  'retro',
  'pixel art',
  'sandbox',
  'space',
  'melee',
  'fighting',
  'word games',
  'driver simulator',
  'side scroller',
  'rpg',
  'funny',
  'platformer',
  'choices matter',
  'top-down',
  'physics',
  'roguelike',
  'turn-based',
  'one shot',
  '2d',
  BED_WARS_TAG,
].sort((a, b) => (a.toLocaleLowerCase() > b.toLocaleLowerCase() ? 1 : -1));
// "static" tag for LEGO/Rocket Racing islands. This does not get sent/received by the server and does not affect the user's minimum/maximum tags.
export const STATIC_TAG = {
  LEGO: 'LEGO',
  ROCKET_RACING: 'Rocket Racing',
  SPEED_RUN: 'Speedruns',
  COMPETITIVE: 'Competitive',
  FALL_GUYS: 'Fall Guys',
  TMNT: 'TMNT',
};
export const STATIC_TAGS = Object.values(STATIC_TAG);

export const productMode = {
  DeathRace: 'DelMar.DeathRace',
  SpeedRuns: 'DelMar.SpeedRuns',
  Competitive: 'DelMar.Competitive',
};

// It is necessary in order to fix incorrect handling of keys that contain the ":" character. (for example 'difficulty: easy')
export const getTagNameInTranslation = (tagValue: string): string => (tagValue.includes(':') ? tagValue.replaceAll(':', '.') : tagValue);

export const PERSONAL_TEAM_ID = 'personal';

export const DEFAULT_PAGE_URL = `/welcome?team=${PERSONAL_TEAM_ID}`;

export const MAX_TITLE_LENGTH = 40;

export const REQUIRED_INTRODUCTION_LINE_COUNT = 3;

export const MAX_INTRODUCTION_LINE_LENGTH = 150;

export const MAX_DESCRIPTION_LENGTH = 500;
export const MAX_NEW_CHANGES_LENGTH = 500;

export const MAX_IARC_EMAIL_LENGTH = 95;

export const IMG_MIN_WIDTH = 1920;

export const IMG_MIN_HEIGHT = 1080;

export const IMG_SQUARE_SIDE = 1024;
// 10MB in SI units also validated in BE
export const IMG_SQUARE_SIZE = 10 * 1000 * 1000;

export const IMG_ASPECT_RATIO = 16 / 9;
export const IMG_SQUARE_ASPECT_RATIO = 1;
export const IMG_LOBBY_ASPECT_RATIO = 2;

export const IMG_MAX_SIZE = 5 * 1024 * 1024; // 5MB

export const LOBBY_BG_WIDTH = 2048;
export const LOBBY_BG_HEIGHT = 1024;
// 10MB in SI units also validated in BE
export const LOBBY_BG_MAX_SIZE = 10 * 1000 * 1000;

export const IMG_ACCEPTED_FORMATS = ['image/png', 'image/jpeg', 'image/jpg'];

export const TRAILER_ACCEPTED_FORMATS = ['video/mp4', 'video/mkv', 'video/webm', 'video/mov'];
export const TRAILER_MIN_HEIGHT = 1080;
export const TRAILER_MIN_WIDTH = 1920;
export const TRAILER_MAX_SIZE = 400 * 1024 * 1024; // 400MB;
export const TRAILER_MIN_TIME = '10'; // sec;
export const TRAILER_MAX_TIME = '40'; // sec;

export const MAX_FORM_CONTENT_WIDTH = 930;

export const COMMIT_MESSAGE_OF_PRIVATE_VERSION_IN_FNC = '<Placeholder Commit Message>';

export enum DISCOVERY_STATUS {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum MODERATION_STATUS {
  APPROVED = 'Approved',
  PENDING = 'Pending',
  DENIED = 'Denied',
  UNMODERATED = 'Unmoderated',
}

export enum RELEASE_STATUS {
  APPROVED = 'Approved',
  PENDING = 'Pending',
  DENIED = 'Denied',
  ERROR = 'Error',
  UNMODERATED = 'Unmoderated',
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
  NO_RATING = 'No_Rating',
  EXPIRED_RATING = 'Expired_Rating',
  REGIONAL_RESTRICTIONS = 'Regional_Restrictions',
  CANCELLED = 'Cancelled',
  APPROVED_WITH_EDITS = 'Approved_With_Edits',
}

export enum PROJECT_STATUS {
  PUBLIC = 'PUBLIC',
  UNPUBLISHED = 'UNPUBLISHED',
  DISABLED = 'DISABLED',
  PRIVATE = 'PRIVATE',
  PENDING_REVIEW = 'PENDING_REVIEW',
  ERROR = 'ERROR',
  DENIED = 'DENIED',
  NO_RATING = 'NO_RATING',
  DEACTIVATED = 'DEACTIVATED',
}

export enum CHANGE_PROJECT_DISCOVERY_INTENT {
  PUBLIC = PROJECT_STATUS.PUBLIC,
  PRIVATE = PROJECT_STATUS.PRIVATE,
  DEACTIVATED = PROJECT_STATUS.DEACTIVATED,
  REACTIVATED = 'REACTIVATED',
}

export const ATTRIBUTIONS_LIST_MAX_COUNT = 50;

export const PROJECTS_PER_PAGE = 36;

export const IMAGE_MEDIA_NAME = 'landscape';
export const SQUARE_MEDIA_NAME = 'square';
export const LOBBY_MEDIA_NAME = 'lobby';
export const TRAILER_MEDIA_NAME = 'trailer';
