export interface AnalyticsResponse<T> {
  count: number;
  results: T[];
}

export enum Timescales {
  LIVE = 'live',
  HOURLY = 'hau',
  DAILY = 'dau',
  WEEKLY = 'wau',
  MONTHLY = 'mau',
}

export enum DIFFICULTY_LEVELS {
  TOO_EASY = 'too_easy',
  EASY = 'easy',
  JUST_RIGHT = 'just_right',
  DIFFICULT = 'difficult',
  TOO_DIFFICULT = 'too_difficult',
}

export enum RATING_LEVELS {
  RATE_1 = 'rate_1',
  RATE_2 = 'rate_2',
  RATE_3 = 'rate_3',
  RATE_4 = 'rate_4',
  RATE_5 = 'rate_5',
  RATE_6 = 'rate_6',
  RATE_7 = 'rate_7',
  RATE_8 = 'rate_8',
  RATE_9 = 'rate_9',
  RATE_10 = 'rate_10',
}

export enum FUN_LEVELS {
  NO = 'no',
  YES = 'yes',
}

export type HauDauTimescale = Timescales.HOURLY | Timescales.DAILY;
export type DauTimescale = Timescales.DAILY;

export interface AnalyticsInsightsResponse {
  monthly: {
    activePlayers: number;
    change: number;
    data: number[];
  };
  weekly: {
    activePlayers: number;
    change: number;
    data: number[];
  };
  daily: {
    activePlayers: number;
    change: number;
    data: number[];
  };
}

export interface AnalyticsNotification {
  id: number;
  severity: 'success' | 'info' | 'warning' | 'error';
  title: string;
  message: string;
  dismissable?: boolean;
  link?: {
    label: string;
    href: string;
  };
}

export interface AnalyticsProject {
  projectId: number;
  title: string;
  activePlayers: number;
  change: number;
  data: number[];
}

export interface AnalyticsStatsResponse {
  mostPopular: AnalyticsProject;
  mostGrowth: AnalyticsProject;
  stats: {
    activePlayers: number;
    change: number;
    data: number[];
  };
}

export interface AnalyticsInteraction {
  interactionType: string;
  eventCategory: string;
  eventAction: string;
  eventLabel?: string;
  eventValue?: number;
  portalAccountId?: string;
  data?: {
    checked?: boolean | string;
    settingKey?: string;
    count?: number;
    creatorCode?: string;
    profileType?: string;
  };
}

interface IAnalyticsBase {
  DATE: Date;
  CREATIVE_ISLAND_CODE: string;
}
export interface AnalyticsAccoladesResponse extends IAnalyticsBase {
  PAYLOAD: AnalyticsAccoladesResponsePayload;
}

export interface AnalyticsAccoladesResponsePayload {
  accolades: Record<string, number>;
  percentile?: number;
  versions: string[];
}

export interface AnalyticsPerformanceResponse extends IAnalyticsBase {
  PAYLOAD: AnalyticsPerformanceResponsePayload;
}

export interface AnalyticsPerformanceResponsePayload {
  [key: string]: {
    [key: string]: {
      [key: string]: number | string;
    };
  };
}

export enum PerformanceSources {
  hau = 'perf_v2',
  dau = 'perf_daily_v2',
}

export interface AnalyticsDeviceResponse {
  DT: Date;
  ISLAND_CODE: string;
  PAYLOAD: AnalyticsDeviceResponsePayload[];
}

export interface AnalyticsDeviceResponsePayload {
  guid_id: string;
  trigger_count: number;
}

export interface AnalyticsUsersResponse {
  DT: Date;
  CREATIVE_ISLAND_CODE: string;
  PAYLOAD: AnalyticsUsersResponsePayload;
}

export interface AnalyticsUsersResponsePayload {
  date: Date;
  new_players: number;
  returning_players: number;
}

export interface AnalyticsActiveUsersResponse {
  DT: string;
  CREATIVE_ISLAND_CODE: string;
  ACTIVE: number;
  RETAINED?: number;
  RECURRING?: number;
  ACTIVE_RANK?: number | string;
}

export interface AnalyticsActivePlaytimeResponse {
  DT: string;
  CREATIVE_ISLAND_CODE: string;
  ACTIVE_MINS: number;
  PSVS?: number;
  ACTIVE_RANK?: number | string;
}
export interface FunResponse {
  DT: string;
  YES_RESPONSES: number;
  NO_RESPONSES: number;
}

export type EngagementPlayersDataItem = Pick<AnalyticsActiveUsersResponse, 'DT' | 'ACTIVE' | 'RETAINED' | 'RECURRING'>;

export type EngagementPlayersData = EngagementPlayersDataItem[];

export type ActivePlayersDataItem = Pick<AnalyticsActiveUsersResponse, 'DT' | 'ACTIVE'>;

export type ActivePlayersData = ActivePlayersDataItem[];

export type EngagementTimeDataItem = Pick<AnalyticsActivePlaytimeResponse, 'DT' | 'ACTIVE_MINS' | 'PSVS'>;

export type EngagementTimeData = EngagementTimeDataItem[];

export type ActivePlaytimeDataItem = Pick<AnalyticsActivePlaytimeResponse, 'DT' | 'ACTIVE_MINS'>;

export type ActivePlaytimeData = ActivePlaytimeDataItem[];

export interface AnalyticsNewAndReturningUsersResponse {
  DT: string;
  CREATIVE_ISLAND_CODE: string;
  NEW_PLAYERS: number;
  RETURNING_PLAYERS: number;
}
export type FunDataItem = Pick<FunResponse, 'DT' | 'YES_RESPONSES' | 'NO_RESPONSES'>;
export type FunData = FunDataItem[];
export type EngagementUsersDataItem = Pick<AnalyticsNewAndReturningUsersResponse, 'DT' | 'NEW_PLAYERS' | 'RETURNING_PLAYERS'>;

export type EngagementUsersData = EngagementUsersDataItem[];

export interface AnalyticsICPEarningsResponse {
  DT: string;
  CREATIVE_ISLAND_CODE: string;
  EARNINGS: number;
}

export type ChartEarningsIcpDataItem = Pick<AnalyticsICPEarningsResponse, 'DT' | 'EARNINGS'>;

export type ChartEarningsIcpData = ChartEarningsIcpDataItem[];

export interface AnalyticsImpressionsResponseEntry {
  NAME: string;
  TOTAL_IMPRESSIONS: number;
}

export interface AnalyticsTotalImpressionsResponse {
  DT: string;
  CREATIVE_ISLAND_CODE: string;
  TOTAL_IMPRESSIONS: number;
}

export interface AnalyticsCountryImpressionsResponse {
  DT: string;
  COUNTRIES: AnalyticsImpressionsResponseEntry[];
}

export interface AnalyticsPlatformImpressionsResponse {
  DT: string;
  PLATFORMS: AnalyticsImpressionsResponseEntry[];
}

export interface AnalyticsSourceImpressionsResponse {
  DT: string;
  SOURCES: AnalyticsImpressionsResponseEntry[];
}

export type ImpressionsDataItem = Pick<AnalyticsTotalImpressionsResponse, 'DT' | 'TOTAL_IMPRESSIONS'>;

export type ImpressionsData = ImpressionsDataItem[];

export interface AnalyticsTotalPlaysResponse {
  DT: string;
  CREATIVE_ISLAND_CODE: string;
  TOTAL_PLAYS: number;
  PTR?: number;
}

export type PlaysDataItem = Pick<AnalyticsTotalPlaysResponse, 'DT' | 'TOTAL_PLAYS'>;

export type PlaysData = PlaysDataItem[];

export type PTRDataItem = Pick<AnalyticsTotalPlaysResponse, 'DT' | 'PTR'>;

export type PTRData = PTRDataItem[];

export interface AnalyticsTotalClicksResponse {
  DT: string;
  CREATIVE_ISLAND_CODE: string;
  TOTAL_CLICKS: number;
  CTR?: number;
}

export interface AnalyticsCountryClicksResponse {
  DT: string;
  COUNTRIES: AnalyticsClicksResponseEntry[];
}

export interface AnalyticsPlatformClicksResponse {
  DT: string;
  PLATFORMS: AnalyticsClicksResponseEntry[];
}

export interface AnalyticsSourceClicksResponse {
  DT: string;
  SOURCES: AnalyticsClicksResponseEntry[];
}

export interface AnalyticsClicksResponseEntry {
  NAME: string;
  TOTAL_CLICKS: number;
}

export type TotalCliksDataItem = Pick<AnalyticsTotalClicksResponse, 'DT' | 'TOTAL_CLICKS'>;

export type TotalCliksData = TotalCliksDataItem[];

export type CTRDataItem = Pick<AnalyticsTotalClicksResponse, 'DT' | 'CTR'>;

export type CTRData = CTRDataItem[];

export interface AnalyticsSessionLengthResponse {
  SESSION_MINUTES: number;
  COUNT_SESSIONS: number;
}

export type SessionLengthDataItem = Pick<AnalyticsSessionLengthResponse, 'SESSION_MINUTES' | 'COUNT_SESSIONS'>;

export type SessionLengthData = SessionLengthDataItem[];

export type AnalyticsSurveyResponse = {
  DT: string;
  PAYLOAD: AnalyticsSurveyResponsePayload[];
};

export type AnalyticsSurveyResponsePayload = {
  R_TEXT: string;
  R_ORDER: number;
  RESPONSE_COUNT: number;
};

export type AnalyticsSurveyResponsePayloadDataItem = Pick<AnalyticsSurveyResponsePayload, 'R_ORDER' | 'RESPONSE_COUNT'>;
export type AnalyticsSurveyResponsePayloadData = AnalyticsSurveyResponsePayloadDataItem[];

export type AnalyticsSurveyResponseDataItem = Pick<AnalyticsSurveyResponse, 'DT'> & Record<string, number>;
export type AnalyticsSurveyResponseData = AnalyticsSurveyResponseDataItem[];

export type AnalyticsSurveyResponseDistributionDataItem = Pick<AnalyticsSurveyResponsePayload, 'R_ORDER'> & {
  RESPONSE_DISTRIBUTION_COUNT: number;
};
export type AnalyticsSurveyResponseDistributionData = AnalyticsSurveyResponseDistributionDataItem[];

export type AverageRatingData = {
  DT: string;
  AVERAGE_RATING: number;
}[];

export interface AnalyticsFavoritesResponse {
  DT: string;
  FAVORITES_NET_CUMULATIVE: number;
}

export type RatingOverTimeData = {
  DT: string;
  [RATING_LEVELS.RATE_1]: number;
  [RATING_LEVELS.RATE_2]: number;
  [RATING_LEVELS.RATE_3]: number;
  [RATING_LEVELS.RATE_4]: number;
  [RATING_LEVELS.RATE_5]: number;
  [RATING_LEVELS.RATE_6]: number;
  [RATING_LEVELS.RATE_7]: number;
  [RATING_LEVELS.RATE_8]: number;
  [RATING_LEVELS.RATE_9]: number;
  [RATING_LEVELS.RATE_10]: number;
}[];

export type AnalyticsFavoritesDataItem = Pick<AnalyticsFavoritesResponse, 'DT' | 'FAVORITES_NET_CUMULATIVE'>;

export enum SatisfactionCsvKeys {
  RATING_SUMMARY = 'rating_summary',
  RATING_OVER_TIME = 'rating_over_time',
  RATING_OVER_TIME_AVERAGE = 'rating_over_time_average',
  RATING_COMPARE = 'rating_compare',
  HAVE_FUN_SUMMARY = 'have_fun_summary',
  HAVE_FUN_OVER_TIME = 'have_fun_over_time',
  HAVE_FUN_COMPARE = 'have_fun_compare',
  DIFFICULTY_SUMMARY = 'difficulty_summary',
  DIFFICULTY_OVER_TIME = 'difficulty_over_time',
  DIFFICULTY_COMPARE = 'difficulty_compare',
}
