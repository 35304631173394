export const getPrivacyUrl = (lang: string): string => `https://www.epicgames.com/site/${lang}/privacypolicy`;
export const getRocketRacingUrl = (lang: string): string =>
  `https://dev.epicgames.com/documentation/${lang}/uefn/working-with-rocket-racing-islands-in-unreal-editor-for-fortnite`;
export const getInternationalAgeRatingCoalitionUrl = (lang: string): string =>
  `https://dev.epicgames.com/documentation/${lang}/fortnite-creative/iarc-overview-and-faqs-in-fortnite-creative`;
export const getEngagementPayoutsInFortniteCreativeUrl = (lang: string): string =>
  `https://dev.epicgames.com/documentation/${lang}/fortnite-creative/engagement-payouts-in-fortnite-creative`;
export const getPublishingFromCreatorPortalInFortniteCreativeUrl = (lang: string): string =>
  `https://dev.epicgames.com/documentation/${lang}/fortnite-creative/publishing-from-the-creator-portal-in-fortnite-creative`;
export const getManagingCreatorProfileDocUrl = (lang: string): string =>
  `https://dev.epicgames.com/documentation/${lang}/fortnite-creative/managing-your-creator-profile-in-fortnite-creative`;
export const getAccountSettingPageUrl = (lang: string): string => `https://www.epicgames.com/account/creator-programs?${lang}`;
export const getAccountPayoutMethodPageUrl = (lang: string): string =>
  `https://www.epicgames.com/account/messaging?section=email&lang=${lang}`;
export const getTwofactorAuthenticationHelpUrl = (lang: string): string =>
  `https://www.epicgames.com/help/epic-accounts-c74/account-security-c112/twofactor-authentication-and-how-to-enable-it-a3218?lang=${lang}`;
export const getTaxesAndPayoutsPrivacyPolicyurl = (lang: string): string => `https://epicgames.com/privacypolicy?lang=${lang}`;
export const getSacPayoutStoryPageUrl = (lang: string): string => `https://sac.epicgames.com/${lang}/settings/payout-history`;
export const getOurCreatorProgramsUrl = (lang: string): string =>
  `https://www.epicgames.com/help/${lang}/our-creator-programs-c5719349318939`;
export const getTaxIdentityUrl = (lang: string): string => `https://taxidentity.com/?lang=${lang}`;
export const getContentGuidelinesUrl = (lang: string): string => `https://www.epicgames.com/site/${lang}/content-guidelines`;
export const getEpicsPicksUrl = (lang: string): string => `https://www.fortnite.com/news/introducing-epics-picks-in-discover?lang=${lang}`;
export const getEpicsPicksApplyNowUrl = (lang: string): string =>
  `https://creative.fortnite.com/s/?language=${lang}&token=74T05pNI5M1LTLfLPkrn26Wt5OEpmLwkdlts`;
export const getLegoBrandAndCreatorGuidelinesInFortniteCreativeUrl = (lang: string): string =>
  `https://dev.epicgames.com/documentation/${lang}/fortnite-creative/lego-brand-and-creator-rules-in-fortnite-creative`;
export const getDeactivatingIslandsInFortniteCreativeUrl = (lang: string): string =>
  `https://dev.epicgames.com/documentation/${lang}/fortnite-creative/deactivate-islands-in-fortnite-creative`;
export const getSupportACreatorRulesUrl = (lang: string): string => `https://sac.epicgames.com/${lang}/eula/sac`;
export const getSACInsightsUrl = (lang: string): string => `https://www.epicgames.com/affiliate/${lang}/fortnite-games/insights`;
export const getSACRewardsUrl = (lang: string): string => `https://www.epicgames.com/affiliate/${lang}/fortnite-games/rewards`;
export const getWorkingWithFallGuysIslandsInFortniteCreativeUrl = (lang: string): string =>
  `https://dev.epicgames.com/documentation/${lang}/fortnite-creative/working-with-fall-guys-islands-in-fortnite-creative`;
export const getWorkingWithTmntIslandsInFortniteCreativeUrl = (lang: string): string =>
  `https://dev.epicgames.com/documentation/${lang}/uefn/tmnt-creator-and-brand-rules-in-unreal-editor-for-fortnite`;
export const getSetUpTaxProfileUrl = (lang: string): string =>
  `https://dev.epicgames.com/documentation/${lang}/fortnite-creative/set-up-your-tax-profile-in-fortnite-creative`;
export const getProjectMarketingPromotionUrl = (): string => `https://epicgames.ent.box.com/f/ffdc0723b1a04089b2d9c4732dcecfea`;
export const getAttributionsLearnMoreUrl = (lang: string): string =>
  `https://dev.epicgames.com/documentation/${lang}/fortnite-creative/third-party-assets-in-fortnite-creative`;
export const getLobbyBackgroundImageGuidelinesUrl = (lang: string): string =>
  `https://dev.epicgames.com/documentation/${lang}/fortnite-creative/lobby-background-image-guidelines-in-fortnite-creative`;
