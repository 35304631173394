globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"9vqC_b-NKUyW0GgF12kyK"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "/creator-portal/static/d54fc027e6edabec08bd26815b666cfed4c801fe";
const Sentry = require('@sentry/nextjs');

const { NEXT_PUBLIC_SENTRY_DSN: SENTRY_DSN, EPIC_BUILD_RELEASE_VERSION, NODE_ENV } = process.env;
const release = `creator-portal-web@${EPIC_BUILD_RELEASE_VERSION}`;

if (NODE_ENV === 'production' && SENTRY_DSN) {
  process.env.EPIC_ENV = process.env.EPIC_ENV || 'prod';
  process.env.EPIC_DEPLOYMENT = process.env.EPIC_DEPLOYMENT || 'unknown';

  const environment = `${process.env.EPIC_ENV}-${process.env.EPIC_DEPLOYMENT}`;

  const beforeSend = (event) => {
    if (event?.request?.cookies) {
      delete event.request.cookies['EPIC_EG1'];
      delete event.request.cookies['EPIC_EG1_REFRESH'];
    }

    return event;
  };

  Sentry.init({
    dsn: SENTRY_DSN,
    release,
    environment,
    tracesSampleRate: 1.0,
    beforeSend,
  });
}
